



















































































































import {Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import Component from 'vue-class-component'
import Tooltip from '@/components/tooltip/Tooltip.vue'
import TooltipMobileContent from '@/components/tooltip/TooltipMobileContent.vue'
import GasCalculatorFooter from '@/components/gasCalculator/GasCalculatorFooter.vue'
import GasCalculatorTable from '@/components/gasCalculator/GasCalculatorTable.vue'

@Component({
  components: {
    GasCalculatorTable,
    GasCalculatorFooter,
    TooltipMobileContent,
    Tooltip,
  },
})
export default class GasRewardsCard extends Mixins(MixinScreenSize) {
  @Prop({type: Number, required: true}) dailyAmount!: number
  @Prop({type: Number, required: true}) weeklyAmount!: number
  @Prop({type: Number, required: true}) monthlyAmount!: number
  @Prop({type: Number, required: true}) yearlyAmount!: number
  @Prop({type: Number, required: true}) apr!: number
  @Prop({type: Number, required: true}) gasPriceInDollar!: number
  @Prop({type: String, required: true}) lastTimeUpdated!: string
  @Prop({type: Boolean, required: true}) useNeoBurger!: boolean
  @Prop({type: Boolean, required: false, default: false})
  isSameCandidate!: boolean
  @Prop({type: Boolean, required: false, default: false})
  isNoCandidate!: boolean
  @Prop({type: Boolean, required: false, default: false})
  hasInsufficientNeo!: boolean
  @Prop({type: Boolean, required: true}) useNeoCompounder!: boolean
  @Prop({type: String, required: false, default: 'text-2xl md:text-3xl'})
  titleClass!: string

  isTooltipMobileOpen = false

  handleClickChangeVote() {
    this.$emit('onClickChangeVote')
  }

  setIsTooltipMobileOpen(val: boolean) {
    this.isTooltipMobileOpen = val
  }
}
