var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"verti items-start w-full"},[_c('p',{staticClass:"text-regent-gray font-bold self-start uppercase mb-9 sm:mb-10 text-2xl text-center sm:text-left sm:text-xl px-10 sm:px-0"},[_vm._v(" "+_vm._s(_vm.$translate( 'components.MyWalletSwapTokenToReceiveInputAndSelect.chooseTokenToReceive' ))+" ")]),_c('div',{staticClass:"w-full horiz items-center gap-y-2"},[(_vm.token && _vm.isTokenToUseIndivisible)?_c('div',{staticClass:"verti max-w-1/2 sm:max-w-3/5 w-full"},[_c('div',{staticClass:"horiz items-center mb-1"},[_c('span',{staticClass:"text-slate-blue text-3xl font-bold pr-2 truncate max-w-3/4"},[_vm._v(" "+_vm._s(_vm.amount || '0.00')+" ")]),_c('img',{staticClass:"w-7 h-7 object-contain",attrs:{"alt":"lock icon","src":require("@/assets/img/lock.svg")}})]),(_vm.showDollarAmount)?_c('span',{staticClass:"font-medium text-xl text-regent-gray truncate w-full pr-6"},[_vm._v(" "+_vm._s(_vm.$translate( 'components.MyWalletSwapTokenToReceiveInputAndSelect.priceFormat', { price: _vm.amountInDollar, } ))+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"input-group input-group--select flex-grow swap-input-select__select-container"},[_c('vue-multiselect',{staticClass:"input-group__input multiselect--hide-checkbox multiselect--show-placeholder",class:{
          'multiselect--invalid': _vm.selectIsValid === false,
        },attrs:{"allowEmpty":false,"clearOnSelect":false,"closeOnSelect":true,"multiple":false,"options":_vm.options,"searchable":false,"showLabels":true,"value":_vm.token,"deselectLabel":"","placeholder":"Select token","selectLabel":"","trackBy":"symbol"},on:{"select":_vm.handleSelect},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
        var option = ref.option;
return [_c('div',{staticClass:"horiz items-center-center gap-x-2 mr-4 px-2"},[(option.imageUrl)?_c('img',{staticClass:"w-12 h-12 sm:w-14 sm:h-14 shadow-md rounded-full mr-3",attrs:{"src":option.imageUrl,"alt":"token icon"}}):_vm._e(),_c('span',{staticClass:"text-2xl sm:text-3xl font-medium text-slate-blue"},[_vm._v(" "+_vm._s(option.symbol)+" ")])])]}},{key:"option",fn:function(ref){
        var option = ref.option;
return [_c('div',{staticClass:"horiz items-center hover:font-bold gap-x-2"},[(option.imageUrl)?_c('img',{staticClass:"w-12 h-12 sm:w-14 sm:h-14 shadow-md rounded-full mr-3",attrs:{"src":option.imageUrl,"alt":"token icon"}}):_c('div',{staticClass:"w-14 h-13 mr-3"}),_c('span',{staticClass:"text-2xl sm:text-3xl",class:{
                'font-bold text-black':
                  _vm.token &&
                  _vm.token.marketInformation &&
                  option.hash === _vm.token.marketInformation.hash,
                'font-medium text-slate-blue':
                  _vm.token === null ||
                  (_vm.token &&
                    _vm.token.marketInformation &&
                    option.hash !== _vm.token.marketInformation.hash),
              }},[_vm._v(" "+_vm._s(option.symbol)+" ")])])]}}])})],1)]),(_vm.token && !_vm.isTokenToUseIndivisible)?_c('div',{staticClass:"w-full mt-10"},[_c('div',{staticClass:"horiz items-center-center border-solid border-2 bg-white rounded-full py-2 w-full px-7 mb-4 cursor-text",class:{
        'border-light-cyan-blue': _vm.inputIsValid === null,
        'border-coral-red': _vm.inputIsValid === false,
        'border-primary': _vm.inputIsValid === true,
      },on:{"click":_vm.focusOnInput}},[_c('swap-input',{staticClass:"swap-input-select__input",attrs:{"token":_vm.token,"value":_vm.amount},on:{"input":_vm.handleInput}})],1),_c('div',{staticClass:"horiz justify-between"},[(_vm.showDollarAmount)?_c('p',{staticClass:"text-slate-blue font-medium text-xl ml-2 text-left"},[_vm._v(" "+_vm._s(_vm.$translate( 'components.MyWalletSwapTokenToReceiveInputAndSelect.priceFormat', { price: _vm.amountInDollar, } ))+" ")]):_vm._e(),(!_vm.isTokenToReceiveIndivisible)?_c('tooltip',{attrs:{"label":_vm.$translate('components.MyWalletSwapTokenToUseInput.estimated'),"text":_vm.$translate(
            'components.MyWalletSwapTokenToUseInput.estimatedTooltip'
          )},on:{"onMobileOpen":_vm.setMobileTooltip}}):_vm._e()],1)]):_vm._e(),_c('tooltip-mobile-content',{staticClass:"mt-2",attrs:{"show":_vm.mobileTooltipIsVisible,"text":_vm.$translate('components.MyWalletSwapTokenToUseInput.estimatedTooltip')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }