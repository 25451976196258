








































import {Vue, Component, Prop} from 'vue-property-decorator'
import CandidateVoteDetailsCard from '@/components/candidateVote/CandidateVoteDetailsCard.vue'
import {GasCalculatorTarget} from '@/model/resource/GasCalculatorTarget'

@Component({
  components: {CandidateVoteDetailsCard},
})
export default class CandidateVoteDetailsStep extends Vue {
  @Prop({type: Number, required: false, default: 0})
  gasCurrentPriceInDollar!: number
  @Prop({type: Number, required: false, default: 0})
  selectedCandidateDailyAmount!: number
  @Prop({type: Number, required: false, default: 0})
  currentCandidateVotedDailyAmount!: number
  @Prop({type: GasCalculatorTarget, required: true})
  selectedCandidate!: GasCalculatorTarget
  @Prop({type: GasCalculatorTarget, required: true})
  currentCandidateVoted!: GasCalculatorTarget

  async handleProceedClick() {
    this.$emit('onProceedClick')
  }
}
