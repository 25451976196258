var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gas-calculator-footer"},[_c('div',{staticClass:"w-full flex justify-between items-center"},[_c('a',{staticClass:"flex text-primary h-10 items-center text-md font-bold align-center",attrs:{"href":"https://neo.org/gov","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('components.gasCalculator.gasCalculatorFooter.learnMore'))+" "),_c('em',{staticClass:"ml-2 text-xl text-primary fas fa-angle-right"})]),(
        _vm.$store.state.walletAdapter.connectedWalletPlatform &&
          !_vm.useNeoBurger &&
          !_vm.useNeoCompounder &&
          !_vm.isSameCandidate &&
          !_vm.isNoCandidate &&
          !_vm.hasInsufficientNeo
      )?_c('button',{staticClass:"btn btn--contrast bg-black text-base leading-3 p-0 gas-calculator-footer__btn",on:{"click":_vm.handleClickChangeVote}},[_vm._v(" "+_vm._s(_vm.$t('components.gasCalculator.gasCalculatorFooter.changeVote'))+" "),_c('em',{staticClass:"ml-2 text-lg md:text-2xl text-aquamarine fas fa-angle-right"})]):_c('tooltip',{attrs:{"label":_vm.$translate('components.gasCalculator.gasCalculatorFooter.changeVote'),"btn-class":"gas-calculator-footer__tooltip-btn gas-calculator-footer__btn"},on:{"onMobileOpen":_vm.handleMobileOpen}},[_c('gas-calculator-change-vote-tooltip-content',{attrs:{"has-insufficient-neo":_vm.hasInsufficientNeo,"is-no-candidate":_vm.isNoCandidate,"is-same-candidate":_vm.isSameCandidate,"use-neo-burger":_vm.useNeoBurger,"use-neo-compounder":_vm.useNeoCompounder}})],1)],1),_c('tooltip-mobile-content',{attrs:{"show":_vm.isMobileTooltipVisible}},[_c('gas-calculator-change-vote-tooltip-content',{attrs:{"has-insufficient-neo":_vm.hasInsufficientNeo,"is-no-candidate":_vm.isNoCandidate,"is-same-candidate":_vm.isSameCandidate,"use-neo-burger":_vm.useNeoBurger,"use-neo-compounder":_vm.useNeoCompounder}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }