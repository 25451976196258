





























































































import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import SwapInput from '@/components/swapToken/SwapInput.vue'
import {PropType} from 'vue'
import TooltipMobileContent from '@/components/tooltip/TooltipMobileContent.vue'
import Tooltip from '@/components/tooltip/Tooltip.vue'
import {TokensTableItem} from '@/model/resource/TokensTableItem'
import SwapTokenAccountBalance from '@/components/swapToken/SwapTokenAccountBalance.vue'
import {BalanceResponse} from '@cityofzion/blockchain-service'

@Component({
  components: {
    Tooltip,
    TooltipMobileContent,
    SwapInput,
    SwapTokenAccountBalance,
  },
})
export default class MyWalletSwapTokenToUseInput extends MixinScreenSize {
  @Prop({type: TokensTableItem, required: true}) token!: TokensTableItem
  @Prop({type: ((null as unknown) as Object) as PropType<Boolean | null>})
  isValid!: boolean | null
  @Prop({type: String, required: true}) amount!: string
  @Prop({type: String, required: true}) amountInDollar!: string
  @Prop({type: Boolean, required: false, default: true})
  showDollarAmount!: boolean
  @Prop({type: Boolean, required: false, default: true}) showEstimated!: boolean
  @Prop({type: Array, required: true}) accountBalance!: BalanceResponse[]

  @Prop({type: Boolean, required: true}) isTokenToReceiveIndivisible!: boolean

  alreadyImputed = false
  tokenAccountBalance: number = 0

  created() {
    this.setTokenAccountBalance()
  }

  handleInput(val: string) {
    this.alreadyImputed = true
    this.$emit('input', val)
  }

  focusOnInput() {
    const input = this.$el.querySelector(
      '.swap-token-to-use-input__input'
    ) as HTMLInputElement
    input.focus()
  }

  setTokenAccountBalance() {
    const balance = this.accountBalance.find(
      balance => balance.token.hash === this.token?.marketInformation?.hash
    )

    this.tokenAccountBalance = Number(balance?.amount ?? 0)
  }
}
