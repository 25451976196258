















































































































































import {Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import Component from 'vue-class-component'
import Tooltip from '@/components/tooltip/Tooltip.vue'
import TooltipMobileContent from '@/components/tooltip/TooltipMobileContent.vue'

@Component({
  components: {
    TooltipMobileContent,
    Tooltip,
  },
})
export default class GasRewardsCard extends Mixins(MixinScreenSize) {
  @Prop({type: Number, required: true}) dailyAmount!: number
  @Prop({type: Number, required: true}) weeklyAmount!: number
  @Prop({type: Number, required: true}) monthlyAmount!: number
  @Prop({type: Number, required: true}) yearlyAmount!: number
  @Prop({type: Number, required: true}) gasPriceInDollar!: number

  calculateDollar(gas: number) {
    return (gas * this.gasPriceInDollar).toFixed(2)
  }
}
