var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-wallet-claim-gas-modal"},[_c('modal',{attrs:{"closable":_vm.canBeClosed,"closeOutside":_vm.canBeClosed,"name":"myWalletClaimGasModal"},on:{"close":_vm.closeEvent,"open":_vm.openEvent}},[[(_vm.currentStep === _vm.ClaimGasStep.WAITING_SIGNATURE)?_c('transition',{staticClass:"w-full",attrs:{"mode":"out-in","name":"fade-left"}},[_c('claim-gas-loading-transaction-step',{attrs:{"description":_vm.$translate(
              'components.MyWalletClaimGasModal.waitingSignatureStep.description'
            ),"in-progress":false,"title":_vm.$translate(
              'components.MyWalletClaimGasModal.waitingSignatureStep.title'
            )}})],1):(_vm.currentStep === _vm.ClaimGasStep.VALIDATING_TRANSACTION)?_c('transition',{attrs:{"mode":"out-in","name":"fade-left"}},[_c('claim-gas-loading-transaction-step',{attrs:{"description":_vm.$translate(
              'components.MyWalletClaimGasModal.validatingTransactionStep.description'
            ),"in-progress":true,"title":_vm.$translate(
              'components.MyWalletClaimGasModal.validatingTransactionStep.title'
            )}})],1):(_vm.currentStep === _vm.ClaimGasStep.UNKNOWN_ERROR)?_c('transition',{attrs:{"mode":"out-in","name":"fade-left"}},[_c('claim-gas-unexpected-error-step')],1):(_vm.currentStep === _vm.ClaimGasStep.SUCCESS)?_c('transition',{attrs:{"mode":"out-in","name":"fade-left"}},[_c('claim-gas-success-step')],1):_vm._e()]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }