





































































































































import {Vue, Component, Prop} from 'vue-property-decorator'
import MyWalletClaimGasModal from '@/components/myWallet/MyWalletClaimGasModal.vue'

@Component({
  components: {MyWalletClaimGasModal},
})
export default class MyWalletUnclaimedGasSection extends Vue {
  @Prop({type: String, required: true}) unclaimedGas!: string
  @Prop({type: String, required: true}) fee!: string

  isProceedDisabled = true

  openClaimGasModal() {
    this.$modal.open('myWalletClaimGasModal')
  }
}
