






















































































import {Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import Component from 'vue-class-component'
import Tooltip from '@/components/tooltip/Tooltip.vue'
import GasCalculatorChangeVoteTooltipContent from '@/components/gasCalculator/GasCalculatorChangeVoteTooltipContent.vue'
import TooltipMobileContent from '@/components/tooltip/TooltipMobileContent.vue'

@Component({
  components: {
    TooltipMobileContent,
    GasCalculatorChangeVoteTooltipContent,
    Tooltip,
  },
})
export default class GasCalculatorFooter extends Mixins(MixinScreenSize) {
  @Prop({type: Boolean, required: true}) useNeoBurger!: boolean
  @Prop({type: Boolean, required: false, default: false})
  isSameCandidate!: boolean
  @Prop({type: Boolean, required: false, default: false})
  isNoCandidate!: boolean
  @Prop({type: Boolean, required: false, default: false})
  hasInsufficientNeo!: boolean
  @Prop({type: Boolean, required: true}) useNeoCompounder!: boolean

  isMobileTooltipVisible = false

  handleMobileOpen(value: boolean) {
    this.isMobileTooltipVisible = value
  }

  handleClickChangeVote() {
    this.$emit('onClickChangeVote')
  }
}
