var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"candidate-vote-modal",class:{
    'candidate-vote-modal-mobile':
      (_vm.isMobile || _vm.isTablet || _vm.isSmallDesktop) &&
      _vm.currentStep === _vm.CandidateVoteStep.REVIEW_FORM,
    'candidate-vote-modal-progress':
      _vm.currentStep !== _vm.CandidateVoteStep.REVIEW_FORM,
  }},[_c('modal',{attrs:{"closable":_vm.canBeClosed,"closeOutside":_vm.canBeClosed,"name":"candidateVoteModal"},on:{"close":_vm.closeEvent,"open":_vm.openEvent}},[(
        _vm.currentCandidateVoted !== null &&
          _vm.selectedCandidate !== null &&
          _vm.gasCurrentPriceInDollar !== null &&
          _vm.selectedCandidateDailyAmount !== null &&
          _vm.currentCandidateVotedDailyAmount !== null
      )?[(_vm.currentStep === _vm.CandidateVoteStep.REVIEW_FORM)?_c('transition',{staticClass:"w-full",attrs:{"mode":"out-in","name":"fade-left"}},[_c('candidate-vote-details-step',{attrs:{"current-candidate-voted":_vm.currentCandidateVoted,"current-candidate-voted-daily-amount":_vm.currentCandidateVotedDailyAmount,"gas-current-price-in-dollar":_vm.gasCurrentPriceInDollar,"selected-candidate":_vm.selectedCandidate,"selected-candidate-daily-amount":_vm.selectedCandidateDailyAmount},on:{"onProceedClick":function($event){return _vm.vote()}}})],1):(_vm.currentStep === _vm.CandidateVoteStep.WAITING_SIGNATURE)?_c('transition',{staticClass:"w-full",attrs:{"mode":"out-in","name":"fade-left"}},[_c('candidate-vote-loading-transaction-step',{attrs:{"description":_vm.$translate(
              'components.candidateVote.waitingVoteSignatureStep.description'
            ),"title":_vm.$translate(
              'components.candidateVote.waitingVoteSignatureStep.title'
            )}})],1):(_vm.currentStep === _vm.CandidateVoteStep.VALIDATING_TRANSACTION)?_c('transition',{attrs:{"mode":"out-in","name":"fade-left"}},[_c('candidate-vote-loading-transaction-step',{attrs:{"description":_vm.$translate(
              'components.candidateVote.validatingVoteTransactionStep.description'
            ),"title":_vm.$translate(
              'components.candidateVote.validatingVoteTransactionStep.title'
            )}})],1):(_vm.currentStep === _vm.CandidateVoteStep.INSUFFICIENT_GAS)?_c('transition',{attrs:{"mode":"out-in","name":"fade-left"}},[_c('candidate-vote-insufficient-gas-error-step')],1):(_vm.currentStep === _vm.CandidateVoteStep.UNKNOWN_ERROR)?_c('transition',{attrs:{"mode":"out-in","name":"fade-left"}},[_c('candidate-vote-unexpected-error-step')],1):(_vm.currentStep === _vm.CandidateVoteStep.SWAPPED)?_c('transition',{attrs:{"mode":"out-in","name":"fade-left"}},[_c('candidate-vote-swapped-step')],1):_vm._e()]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }