













































































import {Component, Prop} from 'vue-property-decorator'
import TokensPriceTable from '@/components/tokensprice/TokensPriceTable.vue'
import MyWalletTokensTable from '@/components/myWallet/MyWalletTokensTable.vue'
import {MyWalletTokensTableItem} from '@/model/resource/MyWalletTokensTableItem'
import Tooltip from '@/components/tooltip/Tooltip.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {BSNeo3Constants, FlamingoSwapHelper} from '@cityofzion/bs-neo3'
import MyWalletSwapModal from './MyWalletSwap/MyWalletSwapModal.vue'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'

@Component({
  components: {
    Tooltip,
    MyWalletTokensTable,
    MyWalletSwapModal,
    TokensPriceTable,
  },
})
export default class MyWalletTokensNftsSection extends MixinScreenSize {
  @Prop({type: Array, required: true})
  myWalletTokensTableItems!: MyWalletTokensTableItem[]
  @Prop({type: String, required: true})
  lastPriceUpdated!: string
  @Prop({type: TokensTableItemsCollection, required: true})
  tokensTableItemsCollection!: TokensTableItemsCollection

  swappableTokensSymbol = FlamingoSwapHelper.listSwappableTokensSymbol(
    BSNeo3Constants.DEFAULT_NETWORK
  )
}
