




























import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class ClaimGasLoadingTransactionStep extends Vue {
  @Prop({type: String, required: true}) title!: string
  @Prop({type: String, required: true}) description!: string
  @Prop({type: Boolean, required: true}) inProgress!: boolean
}
