

































































































import {Component, Prop} from 'vue-property-decorator'
import GasRewardCard from '@/components/gasCalculator/GasRewardsCard.vue'
import GasCalculatorTable from '@/components/gasCalculator/GasCalculatorTable.vue'
import TooltipMobileContent from '@/components/tooltip/TooltipMobileContent.vue'
import Tooltip from '@/components/tooltip/Tooltip.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {GasCalculatorTarget} from '@/model/resource/GasCalculatorTarget'

@Component({
  components: {
    Tooltip,
    TooltipMobileContent,
    GasCalculatorTable,
    GasRewardCard,
  },
})
export default class CandidateVoteDetailsCard extends MixinScreenSize {
  @Prop({type: Boolean, default: false}) isFrom!: boolean
  @Prop({type: Boolean, default: false}) isCurrentCandidateVoted!: boolean
  @Prop({type: Object, required: true}) candidate!: GasCalculatorTarget
  @Prop({type: Number, required: true}) dailyAmount!: number
  @Prop({type: Number, required: true}) gasCurrentPriceInDollar!: number

  weeklyAmount = 0
  monthlyAmount = 0
  yearlyAmount = 0

  isTooltipMobileOpen = false

  created() {
    this.weeklyAmount = this.$utils.weeklyAmount(this.dailyAmount)
    this.monthlyAmount = this.$utils.monthlyAmount(this.dailyAmount)
    this.yearlyAmount = this.$utils.yearlyAmount(this.dailyAmount)
  }

  setIsTooltipMobileOpen(val: boolean) {
    this.isTooltipMobileOpen = val
  }
}
