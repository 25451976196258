var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-wallet-get-gas-token-to-use"},[_c('div',{staticClass:"flex justify-between"},[_c('span',{staticClass:"text-lg md:text-xl text-regent-gray font-bold uppercase"},[_vm._v(" "+_vm._s(_vm.$t('components.MyWalletGetGasTokenToUse.iAmSwapping'))+" ")]),(_vm.isDesktop || _vm.isSmallDesktop)?_c('div',{staticClass:"horiz gap-x-2"},[_c('span',{staticClass:"text-xl text-regent-gray font-medium uppercase"},[_vm._v(" "+_vm._s(_vm.$t('components.MyWalletGetGasTokenToUse.available'))+" ")]),_c('span',{staticClass:"text-xl font-bold",class:{
          'text-regent-gray': _vm.isValid === null || _vm.isValid,
          'text-coral-red': _vm.isValid === false,
        }},[_vm._v(" "+_vm._s(_vm.neoOwned)+" ")])]):_vm._e()]),_c('div',{staticClass:"my-wallet-get-gas-token-to-use__container",class:{
      'border-light-cyan-blue': _vm.isValid === null,
      'border-coral-red': _vm.isValid === false,
      'border-primary': _vm.isValid === true,
    },on:{"click":_vm.focusOnInput}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.computedNeoToUse),expression:"computedNeoToUse"}],staticClass:"my-wallet-get-gas-token-to-use__input",attrs:{"id":"neoInput","autocomplete":"off","inputmode":"numeric","name":"neo-input"},domProps:{"value":(_vm.computedNeoToUse)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.computedNeoToUse=$event.target.value},_vm.handleChangeNeoInputValue]}}),_c('div',{staticClass:"horiz items-center gap-x-2 border-l-2 border-solid px-2 h-10",on:{"click":_vm.focusOnInput}},[_vm._m(0),_c('span',{staticClass:"text-2xl font-medium text-slate-blue uppercase"},[_vm._v(" "+_vm._s(_vm.$translate('components.MyWalletGetGasTokenToUse.neo'))+" ")])])]),(!_vm.isDesktop && !_vm.isSmallDesktop)?_c('div',{staticClass:"horiz gap-x-2 justify-end"},[_c('span',{staticClass:"text-lg md:text-xl text-regent-gray font-medium uppercase"},[_vm._v(" "+_vm._s(_vm.$translate('components.MyWalletGetGasTokenToUse.available'))+" ")]),_c('span',{staticClass:"text-xl font-bold",class:{
        'text-regent-gray': _vm.isValid === null || _vm.isValid,
        'text-coral-red': _vm.isValid === false,
      }},[_vm._v(" "+_vm._s(_vm.neoOwned)+" ")])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"horiz justify-center items-center"},[_c('img',{staticClass:"w-6 h-6",attrs:{"alt":"Neo icon","src":require("@/assets/img/neo.svg")}})])}]

export { render, staticRenderFns }