



















































import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class MyWalletGetGasTokenToReceiveInput extends Vue {
  @Prop({type: String, required: true}) amount!: string
}
