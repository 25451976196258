




































































































































































































import SwapErrorBox from '@/components/swapToken/SwapErrorBox.vue'
import SwapIndivisibleTokenBox from '@/components/swapToken/SwapIndivisibleTokenBox.vue'
import SwapPoweredBy from '@/components/swapToken/SwapPoweredBy.vue'
import SwapSettingsCard from '@/components/swapToken/SwapSettingsCard.vue'
import SwapTokenDetails from '@/components/swapToken/SwapTokenDetails.vue'
import MyWalletSwapTokenToUseInput from './MyWalletSwapTokenToUseInput.vue'
import MyWalletSwapTokenToReceiveInputAndSelect from './MyWalletSwapTokenToReceiveInputAndSelect.vue'
import SwapTransactionDetailsCard from '@/components/swapToken/SwapTransactionDetailsCard.vue'
import SwapTokenInfo from '@/components/swapToken/SwapTokenInfo.vue'
import {PropType} from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {TokensTableItem} from '@/model/resource/TokensTableItem'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import {BalanceResponse} from '@cityofzion/blockchain-service'
import SwapTokenAccountBalance from '@/components/swapToken/SwapTokenAccountBalance.vue'

@Component({
  components: {
    SwapTokenAccountBalance,
    SwapTransactionDetailsCard,
    SwapSettingsCard,
    SwapPoweredBy,
    SwapErrorBox,
    MyWalletSwapTokenToUseInput,
    SwapTokenDetails,
    SwapIndivisibleTokenBox,
    MyWalletSwapTokenToReceiveInputAndSelect,
    SwapTokenInfo,
  },
})
export default class MyWalletSwapFormStep extends MixinScreenSize {
  @Prop({type: Array, required: true}) swappableTokensSymbol!: string[]
  @Prop({type: TokensTableItemsCollection, required: true})
  tokensTableItemsCollection!: TokensTableItemsCollection
  @Prop({type: Array, required: true}) accountBalance!: BalanceResponse[]
  @Prop({type: TokensTableItem, required: true})
  tokenToUse!: TokensTableItem
  @Prop({type: String, required: true}) amountToReceive!: string
  @Prop({type: String, required: true}) amountToReceiveInDollar!: string
  @Prop({
    type: ((null as unknown) as Object) as PropType<Boolean | null>,
    required: true,
  })
  receiveInputIsValid!: boolean | null
  @Prop({
    type: ((null as unknown) as Object) as PropType<TokensTableItem | null>,
    required: true,
  })
  tokenToReceive!: TokensTableItem | null
  @Prop({type: String, required: true}) amountToUse!: string
  @Prop({type: String, required: true}) amountToUseInDollar!: string
  @Prop({
    type: ((null as unknown) as Object) as PropType<Boolean | null>,
    required: true,
  })
  useInputIsValid!: boolean | null
  @Prop({
    type: ((null as unknown) as Object) as PropType<Boolean | null>,
    required: true,
  })
  receiveSelectIsValid!: boolean | null
  @Prop({
    type: ((null as unknown) as Object) as PropType<Boolean | null>,
    required: true,
  })
  useMaxIsValid!: boolean | null
  @Prop({type: Boolean, required: true})
  isTokenToUseIndivisible!: boolean
  @Prop({type: String, required: true}) priceImpact!: string
  @Prop({type: Number, required: true})
  allowedSlippageInPercentage!: number
  @Prop({type: Number, required: true})
  deadlineInMinutes!: number
  @Prop({type: String, required: true})
  minimumReceived!: string
  @Prop({type: String, required: true})
  liquidityProviderFee!: string
  @Prop({type: String, required: true})
  sellingMaximum!: string
  @Prop({
    type: ((null as unknown) as Object) as PropType<Boolean | null>,
    required: true,
  })
  priceImpactIsExtremellyHigh!: boolean
  @Prop({
    type: ((null as unknown) as Object) as PropType<Boolean | null>,
    required: true,
  })
  priceImpactIsHigh!: boolean
  @Prop({
    type: ((null as unknown) as Object) as PropType<Boolean | null>,
    required: true,
  })
  slippageIsHigh!: boolean
  @Prop({type: String, required: true}) priceInverse!: string
  showUnavailableError: boolean = false
  @Prop({type: String, required: true}) lastAmountChanged!: string
  @Prop({type: Array, required: true}) route!: TokensTableItem[]
  @Prop({type: Boolean, required: true}) wrappingNeo!: boolean
  @Prop({type: Boolean, required: true}) unwrappingNeo!: boolean

  tokenToUseOptions: TokensTableItem[] = []
  tokensToReceiveSwappableOptions: TokensTableItem[] = []

  tokenAccountBalance: number = 0

  get isTokenToReceiveIndivisible(): boolean {
    return this.tokenToReceive !== null
      ? this.tokenToReceive.marketInformation?.decimals === 0
      : false
  }

  get isWarningError() {
    return this.priceImpactIsHigh || this.slippageIsHigh
  }

  get isProceedDisabled() {
    return (
      !this.useInputIsValid ||
      !this.receiveInputIsValid ||
      !this.receiveSelectIsValid ||
      !this.useMaxIsValid ||
      this.priceImpactIsExtremellyHigh ||
      this.priceImpactIsHigh
    )
  }

  created(): void {
    this.populateTokenToReceiveSwappableOptions()
    this.populateTokenToUseOptions()
    this.validateUnavailableOptions()
    this.setTokenAccountBalance()
  }

  setTokenAccountBalance() {
    const balance = this.accountBalance.find(
      balance => balance.token.hash === this.tokenToUse?.marketInformation?.hash
    )

    this.tokenAccountBalance = Number(balance?.amount ?? 0)
  }

  handleUpdateDeadlineInMinutes(deadlineInMinutes: number): void {
    this.$emit('update:deadlineInMinutes', deadlineInMinutes)
  }

  handleUpdateAllowedSlippageInPercentage(
    allowedSlippageInPercentage: number
  ): void {
    this.$emit(
      'update:allowedSlippageInPercentage',
      allowedSlippageInPercentage
    )
  }

  addScrollShadow(target: HTMLElement) {
    if (this.isMobile) return

    const {scrollTop} = target
    const headerElement = document.querySelector('.swap-form-step__header')
    const footerElement = document.querySelector('.swap-form-step__footer')

    if (scrollTop > 0) {
      headerElement?.classList.add('shadow-bottom-1/4')
    } else {
      headerElement?.classList.remove('shadow-bottom-1/4')
    }

    const maxScrollTop = target.scrollHeight - target.clientHeight
    if (scrollTop < maxScrollTop) {
      footerElement?.classList.add('shadow-top-1/4')
    } else {
      footerElement?.classList.remove('shadow-top-1/4')
    }
  }

  addScroll(target: HTMLElement) {
    if (target.scrollHeight > target.clientHeight) {
      target.classList.add('overflow-y-scroll')
    } else {
      target.classList.remove('overflow-y-scroll')
    }
  }

  mounted() {
    const element = document.querySelector(
      '.swap-form-step__content'
    ) as HTMLElement

    this.addScrollShadow(element)
    this.addScroll(element)

    element.addEventListener('scroll', event => {
      this.addScrollShadow(event.target as HTMLElement)
    })

    window.addEventListener('resize', () => {
      this.addScroll(element)
      this.addScrollShadow(element)
    })
  }

  updated() {
    const element = document.querySelector(
      '.swap-form-step__content'
    ) as HTMLElement

    this.addScrollShadow(element)
    this.addScroll(element)
  }

  getErrorMessage(): string | undefined {
    if (this.showUnavailableError) {
      const tokensAvailableToReceive = this.tokensToReceiveSwappableOptions
        .map(token => token.symbol)
        .join(', ')

      return this.$translate(
        'components.MyWalletSwapFormStep.errors.unavailableOptionsError',
        {
          tokensAvailableToReceive,
        }
      )
    }

    if (this.useInputIsValid === false || this.receiveInputIsValid === false) {
      return this.$translate(
        'components.MyWalletSwapFormStep.errors.enterValueError'
      )
    }

    if (this.receiveSelectIsValid === false) {
      return this.$translate(
        'components.MyWalletSwapFormStep.errors.enterTokenError'
      )
    }

    if (this.useMaxIsValid === false) {
      return this.$translate(
        'components.MyWalletSwapFormStep.errors.maxValueError'
      )
    }

    if (this.priceImpactIsExtremellyHigh) {
      return this.$translate(
        'components.MyWalletSwapFormStep.errors.priceImpactError'
      )
    }

    if (this.priceImpactIsHigh) {
      return this.$translate(
        'components.MyWalletSwapFormStep.errors.priceImpactWarning',
        {
          priceImpact: this.priceImpact,
        }
      )
    }

    if (this.slippageIsHigh) {
      return this.$translate(
        'components.MyWalletSwapFormStep.errors.slippageWarning'
      )
    }
  }

  getErrorTitle() {
    if (this.useMaxIsValid === false) {
      return this.$translate('components.MyWalletSwapFormStep.errors.error')
    }

    if (
      this.priceImpactIsHigh ||
      this.priceImpactIsExtremellyHigh ||
      this.slippageIsHigh
    ) {
      return this.$translate('components.MyWalletSwapFormStep.errors.warning')
    }

    return this.$translate(
      'components.MyWalletSwapFormStep.errors.inputRequiredError'
    )
  }

  populateTokenToReceiveSwappableOptions(): void {
    this.swappableTokensSymbol.forEach(symbol => {
      if (symbol === this.tokenToUse.symbol) return

      const token = this.tokensTableItemsCollection.items.find(
        token => token.symbol === symbol
      )
      if (!token) return

      this.tokensToReceiveSwappableOptions.push(token)
    })
  }

  populateTokenToUseOptions(): void {
    this.tokensToReceiveSwappableOptions.forEach(token => {
      const tokenToUseBalance = this.accountBalance.find(
        balance => balance.token.hash === token.marketInformation?.hash
      )

      if (!tokenToUseBalance || Number(tokenToUseBalance.amount) <= 0) return

      this.tokenToUseOptions.push(token)
    })
  }

  validateUnavailableOptions(): void {
    if (
      this.accountBalance.length <= 0 ||
      this.tokensToReceiveSwappableOptions.length <= 0
    ) {
      this.showUnavailableError = true
    }
  }

  handleAmountToReceiveInput(val: string): void {
    this.$emit('amountToReceiveInput', val)
  }

  handleAmountToUseInput(val: string): void {
    this.$emit('amountToUseInput', val)
  }

  handleTokenToReceiveSelection(val: TokensTableItem): void {
    this.$emit('tokenToReceiveSelection', val)
  }

  handleMaxClick(): void {
    this.$emit('maxClick')
  }

  handleProceedClick(): void {
    this.$emit('proceedClick')
  }
}
