








































import {Vue, Component} from 'vue-property-decorator'

@Component
export default class CandidateVoteUnexpectedErrorStep extends Vue {}
