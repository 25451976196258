





















































































import {Component, Vue} from 'vue-property-decorator'

@Component
export default class CandidateVoteInsufficientGasErrorStep extends Vue {
  get neoLink() {
    return this.$translate(
      'components.candidateVote.insufficientGasErrorStep.neoLink'
    )
  }
}
