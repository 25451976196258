











































import {Component} from 'vue-property-decorator'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import MyWalletTotalBalanceCard from '@/components/myWallet/MyWalletTotalBalanceCard.vue'

@Component({
  components: {MyWalletTotalBalanceCard, PercentageProgress},
})
export default class MyWalletAddressCard extends MixinScreenSize {
  get address() {
    if (this.isLargeDesktop) {
      return this.$walletAdapter.address
    } else if (
      this.isLessThanTablet ||
      this.isTablet ||
      this.isSmallDesktop ||
      this.isDesktop
    ) {
      return this.$walletAdapter.address?.trim()
        ? `${this.$walletAdapter.address
            .trim()
            .slice(0, 9)}...${this.$walletAdapter.address.trim().slice(-9)}`
        : null
    }

    return this.$walletAdapter.address
  }

  copyToClipboard() {
    if (!this.$walletAdapter.address) return

    this.$utils.copyToClipboard(this.$walletAdapter.address)
    this.$toast.info('system.info.copiedToClipboard')
  }
}
